import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";

export const twoDecimalPrecision = 2;

export const regexForTwoDecimal = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;

export const regexForSixDigitsAndNoValueAfterDecimal = /^[0-9]{1,6}$/;

/**
 * This method is used to convert value upto 2 decimal places, formatter indicates precision accuracy
 * @param number
 * @param formatter
 * @returns formatted number upto 3 decimal
 */
export const getDecimalFormat = (number, formatter) => {
  return number.toFixed(formatter);
};

export const handleMultiSelectChange = (
  _,
  actionMeta,
  multiList,
  multiSelection,
  allSelectOption
) => {
  let newList = multiSelection;
  const { action, option, removedValue } = actionMeta;
  if (action === "select-option") {
    if (option.value === allSelectOption.value) {
      newList = [...multiList];
    } else {
      newList = [...multiSelection];
      newList.push(option);
    }
  } else if (action === "remove-value") {
    newList = multiSelection.filter(
      ({ value }) => value !== removedValue.value
    );
  } else if (action === "clear") {
    newList = [];
  }
  return newList;
};

export const getMultiSelectOptions = (
  multiList,
  multiSelection,
  allSelectOption
) => {
  if (multiList != undefined) {
    if (multiSelection?.length == multiList?.length) {
      return [...multiSelection];
    } else {
      return [allSelectOption, ...multiList];
    }
  }
  return [];
};

/**
 * These are exports for the dropdowns
 */
export const selectAllStakeholders = {
  value: "*",
  label: "All StakeHolders",
};

export const selectAllProtagonist = {
  value: "*",
  label: "All Protagonist",
};

export const selectAllPsel = {
  value: "*",
  label: "All PSEL Standards",
};

export const selectAllNelp = {
  value: "*",
  label: "All NELP Standards",
};

export const selectAllIntasc = {
  value: "*",
  label: "All InTasc Standards",
};

export const selectAllCaep = {
  value: "*",
  label: "All CAEP Standards",
};

export const selectAllAaqepStandards = {
  value: "*",
  label: "All AAQEP Standards",
};

export const selectAllKeyTopics = {
  value: "*",
  label: "All SubTypes",
};

export const selectAllKeyTopicsForFilter = {
  value: "*",
  label: "-- Select All Key Topics --",
};

export const selectAllAccounts = {
  value: "*",
  label: "All Accounts",
};

export const selectAllSimulations = {
  value: "*",
  label: "All Simulations",
};

export const selectAllOptionCohorts = {
  value: "*",
  label: "All Cohorts",
};

export const selectAllStakeholdersForFilter = {
  value: "*",
  label: "-- Select All StakeHolders --",
};

export const selectAllProtagonistFilter = {
  value: "*",
  label: "-- Select All Protagonist --",
};

export const selectAllPselFilter = {
  value: "*",
  label: "-- Select All PSEL Standards --",
};

export const selectAllNelpFilter = {
  value: "*",
  label: "-- Select All NELP Standards --",
};

export const selectAllIntascFilter = {
  value: "*",
  label: "-- Select All InTasc Standards --",
};

export const selectAllCaepFilter = {
  value: "*",
  label: "-- Select All CAEP Standards --",
};

export const selectAllProductTypes = {
  value: "*",
  label: "-- Select All Product Types --",
};

export const selectAllAAQEPForFilter = {
  value: "*",
  label: "-- Select All AAQEP Standards --",
};

export const selectAllStandards = {
  value: "*",
  label: "All Standards",
};

export const selectAllSimulationByAccount = {
  value: "*",
  label: "All Simulations",
};

export const selectAllRegions = {
  value: "*",
  label: "All Regions",
};

export const selectAllEmails = {
  value: "*",
  label: "All Emails",
};

export const altAttribute = Object.freeze({
  logoAlt: "School Sims",
  profileAlt: "Profile",
});

export const ckEditorConfiguration = Object.freeze({
  forClassManagement: {
    toolbar: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "fontColor",
      "|",
      "alignment",
      "|",
      "blockQuote",
      "|",
      "link",
      "|",
      "numberedList",
      "bulletedList",
    ],
  },
  forAdManagement: {
    toolbar: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "fontColor",
      "|",
      "alignment",
      "|",
      "blockQuote",
      "|",
      "link",
      "|",
      "imageUpload",
      "|",
      "numberedList",
      "bulletedList",
    ],
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:alignLeft",
        "alignCenter",
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:alignRight",
        "|",
        "linkImage",
      ],
    },
  },
});

export const base64ToImage = (base64Image, uniqueName) => {
  const base64ImageSplitArray = base64Image.split(",");
  const mimeType = base64ImageSplitArray[0].match(/:(.*?);/)[1];
  const decodedData = atob(
    base64ImageSplitArray[base64ImageSplitArray.length - 1]
  );
  let decodeDataLength = decodedData.length;
  const u8arr = new Uint8Array(decodeDataLength);
  while (decodeDataLength--) {
    u8arr[decodeDataLength] = decodedData.charCodeAt(decodeDataLength);
  }
  return new File([u8arr], `${uniqueName}.${mimeType.split("/")[1]}`, {
    type: mimeType,
  });
};

export const textToDom = (text) => {
  return new DOMParser().parseFromString(text, "text/html");
};

/**
 * This method return the true is substring is contain in value otherwise it return false
 * @param value
 * @param substring
 * @returns Boolean
 */
export const isConatinSubsrting = (value, substring) => {
  return value?.includes(substring);
};

/**
 * This method add the inActive elements in the original list and then return the original list
 * @param orginalList
 * @param inActiveElemetns
 * return Array
 */
export const listWithInActiveElements = (originalList, inActiveElements) => {
  const list = [...originalList];
  const inActive = "InActive";

  if (Array.isArray(inActiveElements)) {
    inActiveElements.forEach((element) => {
      if (isConatinSubsrting(element?.label, inActive)) {
        list.push(element);
      }
    });
  } else {
    if (isConatinSubsrting(inActiveElements?.label, inActive)) {
      list.push(inActiveElements);
    }
  }

  return list;
};

export const MAX_CHARACTER_LIMITS_FOR_ELIPSIS = 450;

/**
 * This method will convert base64 to excel &  return the excel file
 * @param base64String
 */
export const base64ToExcel = (base64String) => {
  // Decode the base64 string
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Creating a Blob from the bytes
  const blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Creating a link element
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "AccreditationReport.xls";

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

/**
 * This method will find account by its value
 * @param arr
 * @param value
 * @returns
 */
export const findAccountByValue = (arr, value) => {
  console.log(arr, "arr", value, "av;ue");
  for (const obj of arr) {
    if (obj.value === value) {
      return { value: obj.value, label: obj.label };
    }
  }
  return null;
};

/**
 * Generic handle KeyDown
 * @param action
 * @returns
 */
export const handleKeyDown = (action: any) => (event: any) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault();
    action();
  }
};

export const customIcon = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon style={{ color: "#EA7F64" }} />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon style={{ color: "#F4B46C" }} />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon style={{ color: "#F5D577" }} />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon style={{ color: "#B9E38F" }} />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon style={{ color: "#7ED580" }} />,
    label: "Very Satisfied",
  },
};
